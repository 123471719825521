import { Brand } from '@finn/ua-vehicle';
import { Slider } from '@finn/ui-modules';
import { config, Locale, useCurrentLocale } from '@finn/ui-utils';
import React from 'react';

import { BrandSliderItem } from '~/types/slider';
import BrandItem from '~/ui-elements/BrandItem';
import { convertBrandsToSliderItems } from '~/utils/brands';

interface IProps {
  title?: string;
  brands: Brand[];
}

const BrandSlider: React.FunctionComponent<IProps> = ({ title, brands }) => {
  const { locale } = useCurrentLocale();

  const items: BrandSliderItem[] = convertBrandsToSliderItems(brands);

  if (locale === Locale.ENGLISH_GERMANY) {
    items.forEach((item) => {
      item.url = `${config.FINN_WEB_URL}/${Locale.GERMAN_GERMANY}/${item.url}`;
    });
  }

  return (
    <div data-testid="brandslider">
      <Slider title={title} titleHeadingSize={3}>
        {items.map((item) => (
          <BrandItem key={item.key} item={item} />
        ))}
      </Slider>
    </div>
  );
};

export default BrandSlider;
